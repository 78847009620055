<template>
  <div class="page-main">
    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      v-bind="tableOptions"
      @cell-dblclick="itemCblclick"
      @keydown="itemEnter"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_operate="{ row }">
        <vxe-button icon="el-icon-edit-outline" title="编辑" circle @click="itemEdit(row)" />
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="tableOptions.pagerConfig" @page-change="pageChange" />
      </template>
    </vxe-grid>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titleListData: [],
      headerFilterUrl: '/admin/gcvip/columnData/tableSearchHeard',
      filterData: {},
      tableOptions: {
        size: 'mini',
        border: true,
        'highlight-hover-row': true,
        'highlight-current-row': true,
        'max-height': this.$util.getViewHeight() - 150,
        'keyboard-config': { isArrow: true },
        pagerConfig: {
          ...this.$constant.page
        },
        columns: [
          { type: 'seq', width: 100, title: '序号' },
          { title: '操作', width: 100, slots: { default: 'default_operate' } }
        ],
        data: []
      }
    }
  },
  mounted() {
    this.getTitleData()
    this.getListData()
  },
  methods: {
    // 获取标题列表数据
    getTitleData() {
      this.$api({
        methods: 'get',
        url: '/admin/gcvip/column/list'
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.titleListData = this.$util.deepCopy(res.data.list)
            res.data.list.forEach((item) => {
              let obj = {
                field: `name_${item.level}`,
                title: item.name,
                slots: { header: 'header_autocomplete' }
              }
              let len = this.tableOptions.columns.length
              this.tableOptions.columns.splice(len - 1, 0, obj)
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.data.msg)
        })
    },
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.tableOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/columnData/table',
        data: {
          filterData: this.filterData,
          currentPage: this.tableOptions.pagerConfig.currentPage,
          pageSize: this.tableOptions.pagerConfig.pageSize
        }
      }).then((res) => {
        if (res.status === 200) {
          this.tableOptions.data = res.data.page.list
          this.tableOptions.pagerConfig.pageSize = res.data.page.pageSize
          this.tableOptions.pagerConfig.total = res.data.page.totalRow
          this.tableOptions.pagerConfig.currentPage = res.data.page.pageNumber
        }
      })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.tableOptions.pagerConfig.currentPage = currentPage
      this.tableOptions.pagerConfig.pageSize = pageSize
      this.getListData()
    },
    // 项-双击
    itemCblclick() {
      let row = this.$refs.refTable.getCurrentRecord()
      this.itemEdit(row)
    },
    // 项-enter
    itemEnter(params) {
      if (params.$event.code === 'Enter') {
        let row = this.$refs.refTable.getCurrentRecord()
        this.itemEdit(row)
      }
    },
    // 项-编辑
    itemEdit(item) {
      let len = this.titleListData.length
      let listId = []
      for (let i = 0; i < len; i++) {
        let key = `id_${i + 1}`
        listId.push(item[key])
      }
      let url = `/goods/category/set?ids=${listId.join(',')}`
      this.$router.push(url)
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
